/* Google Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* Start Global Rules */
*:hover {
  outline: none;
}

img {
  object-fit: cover !important;
}

ul {
  list-style: none;
  padding: 0;
}

.c-pointer {
  cursor: pointer !important;
}

input {
  line-height: normal;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html,
body,
#root,
.App {
  height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
}

body {
  background-color: #fff;
  font-family: 'Inter', 'sans-serif';
  font-size: 1rem;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease;
}

::selection {
  background-color: var(--purple-main-color);
  color: #fff;
}

.fw-bold-500 {
  font-weight: 500;
}

.fw-bold-600 {
  font-weight: 600;
}

.fw-bold-900 {
  font-weight: 900;
}

.the-main-transition-03 {
  transition: var(--tran-03);
}

.main-background-color {
  background-color: var(--purple-main-color) !important;
}

.main-hover-background-color {
  background-color: var(--purple-hover-color);
}

.main-light-background-color {
  background-color: var(--purple-light-color);
}

.main-text-color {
  color: var(--purple-main-color) !important;
}

.main-text-dark-color {
  color: var(--purple-dark-color) !important;
}

.main-bg-color {
  background-color: var(--background-color) !important;
}

.black-text-color {
  color: var(--black-text-color);
}

.gray-text-color {
  color: var(--gray-text-color);
}

.gray-bg-color {
  background-color: var(--gray-bg-color);
}

.main-button-color.disabled {
  opacity: 0.6;
}

.main-button-color {
  background-color: var(--main-button-color);
  color: #fff;
  transition: var(--tran-03);
}

.main-button-color:hover {
  color: #fff;
  background-color: var(--main-button-hover-color);
}

.light-shadow {
  box-shadow: 0 0 4px rgba(0, 29, 109, .11);
}

.text-real-small {
  font-size: 12px !important;
}

.link-as-button {
  background-color: var(--purple-light-color);
  color: #fff;
  cursor: pointer;
  transition: var(--tran-03);
}

.link-as-button:hover {
  background-color: var(--purple-hover-color);
  color: #fff;
}

.light-hover-effect {
  background-color: #fcfcfd;
  transition: var(--tran-03);
}

.light-hover-effect:hover {
  background-color: var(--gray-bg-color);
}

.edit-icon {
  color: var(--purple-light-color);
  cursor: pointer;
  margin-right: 0.25rem;
  transition: var(--tran-03);
}

.edit-icon:hover {
  color: var(--purple-hover-color);
}

.main-background-premium-color {
  background-color: #f59d0b;
}

.remove-icon {
  color: #FE634EFF;
  cursor: pointer;
  transition: var(--tran-03);
}

.remove-icon:hover {
  color: #AD4133FF;
}

.bottom-padding {
  padding-bottom: 70.5px !important;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: 0;
  box-shadow: none;
  border-color: var(--purple-main-color);
}

.main-loading-effect {
  /*background-color: #e0e0e0;*/
  background-color: #e6e3f4c2;
  position: relative;
  overflow: hidden;
}

.main-loading-effect::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(224, 224, 224, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(224, 224, 224, 0) 100%);
  animation: shimmer 1.5s infinite;
}
/* Shimmer animation */
@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.small-circular-progress .CircularProgressbar {
  width: 25px;
}

.background-image-center {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.one-line-limit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.two-lines-limit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.three-lines-limit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.upgrade-holder .link {
  color: var(--purple-main-color);
  border: 1px solid var(--purple-main-color);
  width: fit-content;
  transition: var(--tran-03);
}
.modal-body {
  padding: 10px;
}
.modal-content {
  border: 0;
}
/* End Global Rules */