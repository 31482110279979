/* Start Variables */
:root {
  /* ====== Colors ====== */
  --purple-main-color: #1F1345;
  --purple-dark-color: #482f9f;
  --purple-light-color: #aaa4fe;
  --purple-hover-color: #482fc3;
  --main-button-color: #34B7F1;
  --main-button-hover-color: #0b6f9c;
  --black-text-color: #404145;
  --gray-text-color: #74767e;
  --light-text-color: #e3e3e3;
  --background-color: #E7E7E7;
  --gray-bg-color: #F5F4F9;

  /* ====== Transition ====== */
  --tran-03: all 0.3s ease;
  --tran-04: all 0.4s ease;
  --tran-05: all 0.5s ease;
}
/* End Variables */