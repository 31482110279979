.holder-of-desktop-page {
    min-height: 100vh !important;
    max-width: 475px !important;
}

.horizontal-line {
    border-bottom: 3px dashed #E9E9EB;
    width: 95%;
}

.custom-title-holder .icon-holder {
    border: 1px solid #d1d1d3;
}

.main-header .flag-holder {
    border: 2px solid transparent;
}
.main-header .flag-holder.active {
    border: 2px solid var(--purple-main-color);
}

/* Start The Main Bottom Navbar */
.main-footer {
    background-color: var(--purple-main-color);
    z-index: 1;
    border-top: 1px solid #ebebff;
    width: 100%;
    max-width: 475px !important;
}
.main-footer .bottom-navbar-links .nav-link {
    width: calc(100% / 4);
}
.main-footer .bottom-navbar-links .nav-link .icon {
    color: #fff;
    transition: var(--tran-03);
}
.main-footer .bottom-navbar-links .nav-link .icon svg {
    transition: var(--tran-03);
}
.main-footer .bottom-navbar-links .nav-link .nav-text {
    font-weight: 500;
    font-size: 13px;
    color: #fff;
    transition: var(--tran-03);
}
.main-footer .bottom-navbar-links .nav-link .active {
    background-color: var(--purple-dark-color);
}

/* End The Main Bottom Navbar */
.main-choices-section .choices-content {
    background-color: var(--purple-main-color);
}

.main-verse-page .verse-text-section .custom-card-content {
    background-color: #F7D8CF;
    border: 0.1px solid #bcbcbc;
    z-index: 10;
    border-radius: 1rem 1rem 1rem 0;
}
.main-verse-page .verse-text-section .category-holder {
    background-color: #F7D8CF;
    border: 0.1px solid #bcbcbc;
    transform: skewY(-10deg);
    margin-top: -39px;
    height: 76px;
}

.main-verse-page .verse-text-section .category-holder .category-title {
    background-color: #2A2C38;
    color: #ededed;
    width: fit-content;
    height: 40px;
    bottom: -30px;
    left: 50%;
    transform: translateX(-40%) skewY(10deg) translateY(-50%);
    z-index: 20 !important;
    border-radius: 1rem 1rem 1rem 0;
}
.main-verse-page .verse-text-section .category-holder .category-title span {
    transform: skewY(-10deg);
}
.main-verse-page .verse-text-section .category-holder .category-title::before {
    content: "";
    position: absolute;
    background-color: #2A2C38;
    width: 100%;
    height: 45px;
    transform: skewY(-7deg);
    bottom: -16px;
    z-index: -1;
    border-radius: 0 1rem 1rem 1rem;
}

.main-verse-page .verse-text-section .custom-card-content .image-holder .image-pic {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: 250px;
    object-fit: cover;
}
.main-verse-page .verse-text-section .category-loading {
    height: 50px;
    width: 140px;
    bottom: -30px;
    right: 6rem;
    z-index: 111;
}

.main-verse-page .verse-text-section .placeholder-verse-text,
.main-verse-page .verse-text-section .category-loading,
.main-verse-page .message-section .placeholder-message,
.main-verse-page .prayer-section .placeholder-prayer {
    background-color: #e0e0e0;
    position: relative;
    overflow: hidden;
}

.main-verse-page .verse-text-section .placeholder-verse-text::after,
.main-verse-page .verse-text-section .category-loading::after,
.main-verse-page .message-section .placeholder-message::after,
.main-verse-page .prayer-section .placeholder-prayer::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, rgba(224, 224, 224, 0) 0%, rgba(255, 255, 255, 0.6) 50%, rgba(224, 224, 224, 0) 100%);
    animation: shimmer 1.5s infinite;
}

.main-verse-page .message-section {
    margin-top: 36px;
}
.main-verse-page .message-section .custom-card-content {
    background-color: #DFECF8;
    border: 0.1px solid #bcbcbc;
}
.main-verse-page .prayer-section .custom-card-content {
    background-color: #EBF7DE;
    border: 0.1px solid #bcbcbc;
}

.featured-bible-stories-section .swiper-pagination {
    position: initial !important;
}
.featured-bible-stories-section .swiper-pagination-bullet {
    background-color: var(--purple-light-color) !important;
}
.featured-bible-stories-section .swiper-pagination-bullet-active {
    background-color: var(--purple-main-color) !important;
}
.main-bible-story-page .music-bars {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 50px;
    height: 30px;
}

.main-bible-story-page .music-bars .bar {
    width: 5px;
    background-color: var(--purple-light-color);
    animation: music-bars 1s infinite ease-in-out;
    margin: 0 2px;
    border-radius: 2px;
}

/* Different animations for each bar */
.main-bible-story-page .music-bars .bar:nth-child(1) {
    animation-delay: -0.2s;
}

.main-bible-story-page .music-bars .bar:nth-child(2) {
    animation-delay: -0.4s;
}

.main-bible-story-page .music-bars .bar:nth-child(3) {
    animation-delay: -0.6s;
}

.main-bible-story-page .music-bars .bar:nth-child(4) {
    animation-delay: -0.8s;
}

.main-bible-story-page .music-bars .bar:nth-child(5) {
    animation-delay: -1s;
}

@keyframes music-bars {
    0% {
        height: 10%; /* Minimum height */
    }
    50% {
        height: 100%; /* Maximum height */
    }
    100% {
        height: 10%; /* Back to minimum height */
    }
}

.main-challenge-page .challenge-days-holder {
    border-radius: 15px;
}

.main-challenge-page .challenge-days-holder .current {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 4px rgba(0, 29, 109, .11);
    border: 0.1px solid var(--purple-light-color);
    color: var(--purple-dark-color);
}
.main-challenge-page .challenge-progress .progress-value {
    border: 0.1px solid var(--purple-light-color);
}

.main-challenge-page .challenge-progress .progress {
    height: 12px;
}
.main-challenge-page .challenge-progress .progress-bar {
    background-color: var(--purple-main-color);
}

.main-bookshelf-page .search-holder .search-icon {
    color: var(--purple-light-color);
}

.main-bookshelf-page .search-holder .search-input::placeholder {
    color: var(--purple-light-color);
}
.main-bookshelf-page .search-holder .search-input {
    text-indent: 25px;
    color: var(--purple-main-color);
}

.main-bookshelf-page .search-holder .search-input:focus + .icon-holder .search-icon {
    color: var(--purple-main-color);
}
.main-bookshelf-page .swiper-specials-holder .swiper-pagination {
    position: initial !important;
    font-size: 13px;
    margin-top: 10px;
    color: var(--purple-dark-color);
}
.main-bookshelf-page .swiper-specials-holder .swiper-pagination::before {
    content: 'E-Book';
    margin-right: 5px;
}
.main-bookshelf-page .swiper-specials-holder .swiper-pagination-bullet {
    background-color: var(--purple-light-color) !important;
}
.main-bookshelf-page .swiper-specials-holder .swiper-pagination-bullet-active {
    background-color: var(--purple-main-color) !important;
}

.main-bookshelf-page .swiper-holder-loading .swiper-pagination {
    display: none !important;
}

.main-bookshelf-page .products-by-category .category-item.active {
    background-color: var(--purple-dark-color);
    color: var(--light-text-color);
}

.payment-methods-custom .pay-button .pay-btn {
    background-color: #fff;
    color: #000;
    font-weight: 600;
    height: 47px;
    border-radius: 5px;
    border: 1px solid #555
}
.payment-methods-custom .pay-button .pay-btn .tranzak-logo {
    height: 22px;
}
.payment-methods-custom .pay-button .pay-btn .stripe-logo {
    height: 30px;
}
.payment-methods-custom .pay-button .pay-btn:hover {
    filter: brightness(0.95);
}
.thank-you-content .circle-holder {
    width: fit-content;
}
.thank-you-content .circle-holder .circle-1 {
    background-color: rgba(72, 47, 159, 0.15);
}
.thank-you-content .circle-holder .circle-2 {
    background-color: rgba(72, 47, 159, 0.40);
}
.thank-you-content .circle-holder .circle-3 {
    background-color: var(--purple-dark-color);
}
.main-pricing-plans-items .pricing-plans-holder .plan-item {
    transition: var(--tran-03);
}

.main-pricing-plans-items .pricing-plans-holder .plan-item.active {
    background-color: var(--purple-light-color);
    color: #fff;
}

.main-quiz-container .answer-option {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 0.5rem;
    cursor: pointer;
}

.main-quiz-container .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #e4e4e4;
    display: inline-block;
}

.main-quiz-container .correct-answer {
    border-color: #6af16a;
    padding: 2px;
}
.main-quiz-container .correct-answer .inner-answer{
    display: inline-block;
    background-color: #6af16a !important;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.main-quiz-container .wrong-answer {
    border-color: #ef1616;
    padding: 2px;
}

.main-quiz-container .wrong-answer .inner-answer{
    display: inline-block;
    background-color: #ef1616 !important;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.main-quiz-container .text-correct-answer {
    color: #6af16a;
}

.main-quiz-container .text-wrong-answer {
    color: #ef1616;
}

/* Base styling for the range input */
.player-container input[type="range"] {
    -webkit-appearance: none; /* Remove default styling */
    appearance: none;
    width: 100%;
    height: 8px;
    background: #e0e0e0; /* Unplayed track background */
    outline: none;
}

/* WebKit Browsers (Chrome, Safari) */
.player-container input[type="range"]::-webkit-slider-runnable-track {
    height: 8px;
    background: #e0e0e0; /* Unplayed track background color */
}

.player-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: black; /* Thumb color */
    border-radius: 50%; /* Make thumb circular */
    cursor: pointer;
    margin-top: -3.5px; /* Center thumb on track */
}

.player-container input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(to right, black 0%, black var(--played-percent), #e0e0e0 var(--played-percent), #e0e0e0 100%);
}

/* Firefox */
.player-container input[type="range"]::-moz-range-track {
    height: 8px;
    background: #e0e0e0; /* Unplayed track background */
}

.player-container input[type="range"]::-moz-range-progress {
    background-color: black; /* Played track background */
    height: 8px;
}

.player-container input[type="range"]::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: black; /* Thumb color */
    border-radius: 50%; /* Make thumb circular */
    cursor: pointer;
}

/* Microsoft Edge & IE */
.player-container input[type="range"]::-ms-track {
    width: 100%;
    height: 8px;
    background: #e0e0e0;
    border-color: transparent;
    color: transparent;
}

.player-container input[type="range"]::-ms-fill-lower {
    background: black; /* Played track background */
}

.player-container input[type="range"]::-ms-fill-upper {
    background: #e0e0e0; /* Unplayed track background */
}

.player-container input[type="range"]::-ms-thumb {
    width: 15px;
    height: 15px;
    background: black; /* Thumb color */
    border-radius: 50%;
    cursor: pointer;
}
