.authentication-page .authentication-content input {
    border-radius: 0.75rem;
    background: #fff;
    color: #000000;
    height: 56px;
    text-indent: 25px;
    caret-color: var(--purple-main-color);
    font-size: 14px;
}

.authentication-page .authentication-content .input-icon {
    color: var(--light-text-color);
    transition: var(--tran-03);
}

.authentication-page .authentication-content input::placeholder {
    color: var(--gray-text-color);
}

.authentication-page .authentication-content input:focus {
    outline: 0;
    border-color: var(--purple-main-color);
    box-shadow: none;
}
.authentication-page .authentication-content input:focus + .icon-holder .input-icon {
    color: var(--purple-main-color);
}

.authentication-page .auth-link {
    color: var(--purple-dark-color);
}

.authentication-page .auth-link:hover {
    color: var(--purple-hover-color);
}