/* Start The Main Left Sidebar */
.the-main-sidebar {
    width: 225px;
    height: 100vh;
    background-color: #fff;
    z-index: 1;
}
.the-main-sidebar .icon {
    min-width: 45px;
    color: #A3A4A6;
    transition: var(--tran-03);
}
.the-main-sidebar .nav-text {
    color: #A3A4A6;
    font-size: 16px;
    font-weight: 500;
}
.the-main-sidebar .nav-link .nav-link-item {
    transition: var(--tran-03);
}
.the-main-sidebar .nav-link .active .icon,
.the-main-sidebar .nav-link .active .nav-text,
.the-main-sidebar .nav-link .nav-link-item:hover .icon,
.the-main-sidebar .nav-link .nav-link-item:hover .nav-text {
    color: var(--purple-dark-color);
}
.the-main-sidebar .nav-link .nav-link-item.active {
    background-color: #e6e3f4;
}
.the-main-header .logout-button *,
.the-main-sidebar .logout-button * {
    color: #DC3545FF;
    transition: var(--tran-03);
}
.the-main-header .logout-button:hover *,
.the-main-sidebar .logout-button:hover * {
    color: #5F0D15FF !important;
}
.the-main-sidebar .sidebar-logo-holder {
    height: 70px;
    border-bottom: 1px solid #ebebff;
}
.the-main-sidebar .sidebar-logo-holder .logo-img {
    width: 150px;
}
/* End The Main Left Sidebar */

/* Start The Main Right Content Holder */
.the-main-content-holder {
    transition: all ease .3s;
    background-color: var(--gray-bg-color);
}
.copyright-text {
    padding-bottom: 70px;
}
@media (min-width: 992px) {
    .the-main-content-holder {
        padding-left: 225px;
    }
    .copyright-text {
        padding-bottom: 0;
    }
}
/* End The Main Right Content Holder */

/* Start The Main Header */
.the-main-header {
    height: 70px;
    border-bottom: 1px solid #ebebff;
    border-left: 1px solid #ebebff;
    min-height: 50px;
}
.the-main-header .page-title {
    font-size: 24px;
}
.the-main-header .header-logo-holder .logo-img {
    width: 120px;
}
/* End The Main Header */

/* Start The Main Content */
.the-main-content {
    flex-basis: 0;
    flex-grow: 1;
    padding-bottom: 4rem;
}
/* End The Main Content */

/* Start The Main Bottom Navbar */
.the-main-bottom-navbar {
    height: 70px;
    background-color: #fff;
    z-index: 1;
    border-top: 1px solid #ebebff;
}
.the-main-bottom-navbar .bottom-navbar-links .nav-link {
    width: calc(100% / 6);
}
.the-main-bottom-navbar .bottom-navbar-links .nav-link .icon {
    color: #A3A4A6;
    transition: var(--tran-03);
    height: 30px;
}
.the-main-bottom-navbar .bottom-navbar-links .nav-link .icon svg {
    width: 25px;
    height: 25px;
    transition: var(--tran-03);
}
.the-main-bottom-navbar .bottom-navbar-links .nav-link .nav-text {
    font-weight: 500;
    font-size: 10px;
    color: #A3A4A6;
    transition: var(--tran-03);
}
.the-main-bottom-navbar .bottom-navbar-links .nav-link .active .icon,
.the-main-bottom-navbar .bottom-navbar-links .nav-link .active .nav-text,
.the-main-bottom-navbar .bottom-navbar-links .nav-link .nav-link-item:hover .icon,
.the-main-bottom-navbar .bottom-navbar-links .nav-link .nav-link-item:hover .nav-text {
    color: var(--purple-dark-color);
}
/* End The Main Bottom Navbar */

/* Start Dashboard Page */
.main-dashboard-page .summary-cards-holder {}
.main-dashboard-page .users-stats-holder {
}

.main-dashboard-page {}
.main-dashboard-page {}
.main-dashboard-page {}
/* End Dashboard Page */